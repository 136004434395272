import { action, computed, makeObservable, observable } from 'mobx'
import type { ReactNode } from 'react'

import type { Drawer } from '../ui/(Drawer)/BaseDrawer/Drawer.type'

interface DrawerOption extends Drawer {}

class DrawerService {
  private drawerOptions: DrawerOption[] = []
  private drawerContents: ReactNode[] = []
  private _isOpened: boolean = false

  get isOpened() {
    return this._isOpened
  }

  get first() {
    return {
      children: this.drawerContents[0],
      options: this.drawerOptions[0],
    }
  }

  get last() {
    return {
      children: this.drawerContents[this.drawerContents.length - 1],
      options: this.drawerOptions[this.drawerOptions.length - 1],
    }
  }

  constructor() {
    makeObservable<this, 'drawerOptions' | 'drawerContents' | '_isOpened'>(this, {
      drawerOptions: observable,
      drawerContents: observable,
      _isOpened: observable,
      close: action.bound,
      closeAll: action.bound,
      open: action.bound,
      first: computed,
      last: computed,
      isOpened: computed,
    })
  }

  open(children: ReactNode, newDrawerOptions: DrawerOption) {
    this.drawerContents.push(children)
    this.drawerOptions.push(newDrawerOptions)
    this._isOpened = true
  }

  close() {
    this._isOpened = false
    this.drawerContents.pop()
    this.drawerOptions.pop()
  }

  closeAll() {
    this._isOpened = false
    this.drawerContents = []
    this.drawerOptions = []
  }
}

export const drawerService = new DrawerService()
