export const LEVEL_KOR = {
  1: '하',
  2: '중하',
  3: '중',
  4: '중상',
  5: '상',
} as const

// 선생님앱이랑 학생앱 레벨 차이가 있나봄..? TODO: 확인하기
export const LEVEL_KOR_TEACHER = {
  1: '하',
  2: '중하',
  3: '중',
  4: '상',
  5: '최상',
} as const

export type SimilarLevel = 'EASIER' | 'AS_IS' | 'HARDER' // 유사문제 난이도 EASIER - 더 쉽게 AS_IS - 그대로 HARDER - 더 어렵게

export const SIMILAR_LEVEL_KOR: Record<SimilarLevel, string> = {
  EASIER: '더 쉽게',
  AS_IS: '그대로',
  HARDER: '더 어렵게',
} as const
